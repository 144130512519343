<template>
  <n-h1 style="padding-top: 30px">{{ t("commons.labels.privacy") }}</n-h1>
  <MarkdownViewer :source="content" />
</template>

<script>
import { defineComponent, computed } from "vue";
import MarkdownViewer from "@/components/MarkdownViewer.vue";
import enContent from "@/views/privacy/en_privacy.md";
import esContent from "@/views/privacy/es_privacy.md";
import { useI18n } from "vue-i18n";
import { NH1 } from "naive-ui";
export default defineComponent({
  components: { MarkdownViewer, NH1 },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    return {
      t,
      content: computed(() => {
        switch (locale.value) {
          case "en":
            return enContent;
          case "es":
            return esContent;
          default:
            return enContent;
        }
      }),
    };
  },
});
</script>
